import React, { useEffect, useState } from 'react';
import { Drawer, Spin, Row, Col, Descriptions, Divider } from 'antd';  // 使用 Ant Design 组件
import { warranty_service } from '../service/warranty.service';

import './warrantypop.css'

const Warrantypop = ({ visible, warranty_order_id, onClose }) => {
    const [loading, setLoading] = useState(true);
    const [warrantyData, setWarrantyData] = useState(null);
    const phoneNumber = '400-994-6898'; 
    const wechat = 'gmxfkf';
    const containerStyle = {
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Arial, sans-serif',
    };

    const textStyle = {
        fontSize: '15px', 
        color: '#333', 
        fontWeight: 'bold'
    };

   const linkStyle = {
        fontSize: '17px', 
        color: '#007BFF', 
        textDecoration: 'none', 
    };
    useEffect(() => {
        // console.log(warranty_order_id)
            // 获取保修信息
            setLoading(true);
            warranty_service.getWarrantyOrder(warranty_order_id).then(response => {
                if (response.responseCode === 'RESPONSE_OK') {
                    setWarrantyData(response.data);
                }else{
                    setWarrantyData(null)
                }
                setLoading(false);
            });
        
    }, [warranty_order_id]);

    return (
        <>
            <Drawer
                className='warranty-drawer'
                title={

                    <div className="drawer-title">
                        <span className="title-text">保修信息</span>
                    </div>

                }
                visible={visible}
                onClose={onClose}
                height="45%"
                width="90%"  // 设置 Drawer 的宽度
                footer={null} // 如果不需要底部按钮，配置为 null
                placement="bottom" // 设置 Drawer 显示的位置为底部
            >
            {loading ? (

                <div className="loading-container">
                    <Spin size="large" />
                </div>

            ) : (
            warrantyData ? (

                <div className="warranty-content">
                    <Descriptions 
                        column={1} 
                        bordered
                        className="warranty-descriptions">
                        <Descriptions.Item label="保修状态">
                            {warrantyData.inWarranty > 0
                            ? '质保中'
                            : '未保修'}
                        </Descriptions.Item>
                        <Descriptions.Item 
                            label="售后电话"
                            className="description-item"
                        >
                            {phoneNumber}
                        </Descriptions.Item>
                        <Descriptions.Item 
                            label="客服微信"
                            className="description-item"
                        >
                            {wechat}
                        </Descriptions.Item>
                    </Descriptions>
                    {/* <Divider/> */}
                    {/* <div style={containerStyle}>
                        <span style={textStyle}>联系客服：</span>
                        <a href={`tel:${phoneNumber}`} style={linkStyle}>
                            {phoneNumber}
                        </a>
                    </div> */}
                </div>)
                :(
                    <div className="drawer-title">
                        <span className="status-text">无延保信息</span>
                        <Divider/>
                        <div style={containerStyle}>
                            <span style={textStyle}>联系客服：</span>
                            <a href={`tel:${phoneNumber}`} style={linkStyle}>
                                {phoneNumber}
                            </a>
                        </div>
                    </div>
                )
           )}
        </Drawer>
    </>
    )
};

export default Warrantypop;