import React, { Component } from 'react'
import {  AlignCenterHorizontal, ShieldBan, ShieldCheck } from 'lucide-react';
import { warranty_service } from "../service/warranty.service";
import Warrantypop from "./warrantypop"

import './warrantyInfo.css'
import { message } from 'antd';

export default class WarrantyInfo extends Component {

    constructor(props) {
        super(props);
        
        // 获取当前路径并根据路径设置 messageVisible
        const isListPage = window.location.pathname.includes('list'); 
        
        this.state={
            warranty_status: 0,
            warranty_order_id:'',
            warranty_expire_date:'',
            messageVisible:isListPage,
            isListPage: isListPage,
        }
        this.getWarantyStatus = this.getWarantyStatus.bind(this);
        this.handleWarrantyStatusClick = this.handleWarrantyStatusClick.bind(this);
    }

    componentDidMount(){
        this.getWarantyStatus(this.props.qrcode);
    }

    handleWarrantyStatusClick(warranty_order_id){
        if(warranty_order_id !== ''){
            // 跳转到 'warrantyDetail' 页面
            let url= '/warranty/detail/'+ warranty_order_id
            window.location.href = url
        }
    };

    // 点击保修按钮时，显示 Modal
    warrantyClick = (warranty_order_id) => {
        this.setState({
            warranty_order_id: warranty_order_id,
            modalVisible: true,  // 显示 Modal
        });
    };

    // 关闭 Modal
    closeModal = () => {
        this.setState({
            modalVisible: false,
        });
    };
    
   

    //根据qrcode获取设备保修状态, 并获得当前设备的保修订单id
    getWarantyStatus(qrCode){
        warranty_service.getWarrantyStatus(qrCode).then(response => {
            if(response.responseCode === 'RESPONSE_OK'){
                this.setState({
                    warranty_status: response.data.inWarranty,
                    warranty_order_id: response.data.warrantyOrderId
                })
                warranty_service.getWarrantyOrder(this.state.warranty_order_id).then(response => {
                    if(response.responseCode === 'RESPONSE_OK'){
                        this.setState({
                            warranty_expire_date: response.data.warrantyExpireDate
                        })
                    }
                })
            }
            // console.log(this.state)
        })   
    }

    render() {
        const test_qrcode = ["28A308B152732","28L604A188058"]
        // 获取当前日期和保修到期日期
        const currentDate = new Date();
        
        // 将 yyyy-MM-dd 格式的字符串解析为 Date 对象
        const warrantyExpireDate = new Date(this.state.warranty_expire_date.replace(/-/g, '/')); // 替换 "-" 为 "/" 以兼容解析

        const timeDiff = warrantyExpireDate - currentDate;  // 时间差（以毫秒为单位）
        const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24)); // 将时间差转换为天数（向上取整）

        // 根据剩余天数设置 className
        let warrantyStatusClass;
        if (this.state.warranty_status > 0 && daysLeft < 30 && daysLeft > 0) {
            warrantyStatusClass = 'expiring';  // 如果保修即将过期
        } else {
            warrantyStatusClass = this.state.warranty_status === 2 ? 'extend' 
                                : this.state.warranty_status === 1 ? 'default' 
                                : 'expire';  // 原有的保修状态类名
        }
        

        const panel_row = {
            display: "inline-block",
        };
        
        const shield_style = {
            width: "14px",  
            height: "14px", 
            display: "inline-block",
        };

        const text_style = {
            fontSize: "12px",
            color: "black",
            display: "inline-block",   
        };
        const waring_text_style = {
            fontSize: "8px",
            color: "goldenrod",
            display: "inline-block",   
        };
        const item_style = {
            height: "22px",
            color: "#333",
            borderRadius: "1rem",
            background:"#f2f2f2",
            display:"flex",
            justifyContent:'center',
            alignItems:"center",
            marginLeft:"1px",
            paddingLeft:"4px",
            paddingRight:"4px",
        };
        const dynamicItemStyle = this.state.isListPage ? item_style : {};
        console.log(this.props.qrcode)
        console.log(test_qrcode[0])
        console.log(this.props.qrcode == test_qrcode[0])
        return (     
            <div style={panel_row}>
                {(this.props.qrcode == test_qrcode[0] || this.props.qrcode == test_qrcode[1])
                    &&
                    <div style={dynamicItemStyle} onClick={() => this.handleWarrantyStatusClick(this.state.warranty_order_id)}>
                        {this.state.warranty_status === 2 && this.state.messageVisible && (
                            <div style={text_style}>
                                延保中
                            </div>
                        )}  
                        {this.state.warranty_status === 1 && this.state.messageVisible&& (
                            <div style={text_style}>
                                保修中
                            </div>
                        )} 
                        {this.state.warranty_status === 0 && this.state.messageVisible&& (
                            <div style={text_style}>
                                已过期
                            </div>
                        )} 
                        {(  
                            this.state.warranty_status > 0
                            &&
                            <ShieldCheck 
                                style={shield_style}
                                className={warrantyStatusClass}
                                onClick={() => this.warrantyClick(this.state.warranty_order_id)}
                            />
                        )}
                        {(  
                            this.state.warranty_status == 0
                            &&
                            <ShieldBan
                                style={shield_style}
                                className={warrantyStatusClass}
                                onClick={() => this.warrantyClick(this.state.warranty_order_id)}
                            />
                        )}
                        {warrantyStatusClass === 'expiring' && (
                            <div style={waring_text_style}>
                                即将过期
                            </div>
                        )}
                    </div>
                }
                {/* <Warrantypop
                    visible={this.state.modalVisible}
                    warranty_order_id={this.state.warranty_order_id}
                    onClose={this.closeModal}
                /> */}
            </div>
        )
    }
}