import axios from 'axios'
import { createHashHistory as createHistory } from 'history';

const warranty_url = 'https://microservice.gmair.net/warranty'
// const warranty_url = 'http://localhost:8090/warranty'

axios.interceptors.response.use((response) => {
  return response
}, (err) => {
  if (err.response.status === '401') {
      const history = createHistory();
      //  const history = createHashHistory();
      history.push('/login')
  }
  return Promise.reject(err)
})


function getWarrantyOrder(warrantyOrderId: string) {
  // 将 warrantyOrderId 作为查询参数添加到 URL 中
  let get_order_url = warranty_url + '/get?warrantyOrderId=' + encodeURIComponent(warrantyOrderId);

  return axios.get(get_order_url).then(function (response) {
    return response.data;
  }).catch(() => {
    return { responseCode: 'RESPONSE_ERROR', description: 'fail to process the request' };
  });
}


// 根据qrcode获取保修状态，返回对象中还包括保修订单号
async function getWarrantyStatus(qrcode: string) {
  let get_status_url = warranty_url + '/get/status?qrcode=' + encodeURIComponent(qrcode);

  return axios.get(get_status_url).then(function (response) {
    return response.data;
  }).catch(() => {
    return { responseCode: 'RESPONSE_ERROR', description: 'fail to process the request' };
  });
}


// async function bindQrcodeAndOrderId(qrcode: string, orderId: string) {
//   const response = await fetch(`${warranty_url}/bind/qrcode/add`);
//   if (!response.ok){
//     throw new Error("绑定保修状态失败");
//   }
//   return response.json()
// } 


export const warranty_service = {
    getWarrantyOrder,
    getWarrantyStatus
}