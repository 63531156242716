import React, { Component } from 'react';
// import { CaretRightOutlined } from '@ant-design/icons';
import { warranty_service } from "../service/warranty.service";
import { Divider } from "antd";
import Warrantypop from "./warrantypop";
import Purchasepop from "./purchasepop";
import { Wrench, Clock } from 'lucide-react';

import './warrantydetail.css'

class WarrantyDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warranty_order_id: '', // 初始为空，后面从路由中获取
            created_time: '',
            order_id: '',
            qrcode: '',
            phone_number: '',
            in_warranty: 0,
            warranty_start_date: '',
            warranty_expire_date: '',
            shieldUrl: require("../../material/warranty_icon/sheild.png"),
            questionUrl: require("../../material/warranty_icon/答疑.png"),
            messageVisible: false
        }

        this.get_warranty_order = this.get_warranty_order.bind(this)
    }

    componentDidMount() {
        const { warranty_order_id } = this.props.match.params; // 从路由参数中获取
        this.get_warranty_order(warranty_order_id); // 使用获取的订单id加载数据
    }

    get_warranty_order = (warranty_order_id) => {
        warranty_service.getWarrantyOrder(warranty_order_id).then(response => {
            console.log(response)

            if (response.responseCode === 'RESPONSE_OK') {
                let information = response.data;
                let create_time = information.createdTime;
                let order_id = information.orderId;
                let qrcode = information.qrcode;
                let phone_number = information.phoneNumber; // 注意：这里应该是phoneNumber
                let in_warranty = information.inWarranty;
                let warranty_start_date = information.warrantyStartDate;
                let warranty_expire_date = information.warrantyExpireDate;

                this.setState({
                    warranty_order_id: warranty_order_id,
                    created_time: create_time,
                    order_id: order_id,
                    qrcode: qrcode,
                    phone_number: phone_number,
                    in_warranty: in_warranty,
                    warranty_start_date: warranty_start_date,
                    warranty_expire_date: warranty_expire_date
                })
            }
        })
    }

    // 点击保修按钮时，显示 Modal
    warrantyClick = (warranty_order_id) => {
        this.setState({
            warranty_order_id: warranty_order_id,
            modalVisible: true,  // 显示 Modal
        });
    };

    // 点击保修按钮时，显示 Modal
    purchaseClick = () => {
        this.setState({
            purchaseVisible: true,  // 显示 Modal
        });
    };

    // 关闭 Modal
    closeModal = () => {
        this.setState({
            modalVisible: false,
        });
    };

    // 关闭 Modal
    closePurchaseModal = () => {
        this.setState({
            purchaseVisible: false
        })
    }

    render() {
        // console.log(this.state)
        console.log(this.state.warranty_order_id)
        return (
            // <div className="order-item">
            //     <h2>保修订单详情</h2>
            //     <p><strong>订单号:</strong> {this.state.order_id}</p>
            //     <p><strong>设备二维码:</strong> {this.state.qrcode}</p>
            //     <p><strong>联系电话:</strong> {this.state.phone_number}</p>
            //     <p><strong>保修状态:</strong> {this.state.in_warranty === 1 ? '保修中' : '未保修'}</p>
            //     <p><strong>保修开始日期:</strong> {this.state.warranty_start_date}</p>
            //     <p><strong>保修到期日期:</strong> {this.state.warranty_expire_date}</p>
            //     <p><strong>订单创建时间:</strong> {this.state.created_time}</p>
            // </div>
            <>
                <div class="header">
                    <h1>全面保障·一站式服务</h1>
                    <img class="header-shield" src={this.state.shieldUrl} alt='shield'></img>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <div class="card" >
                        <div class="card-header">
                            <div class="card-header-left">
                                <span class="icon"></span>
                                <span class="title">质保卡</span>
                                {
                                    this.state.in_warranty > 0
                                    &&
                                    <div class="card-header-right">有效期内</div>
                                }
                                {
                                    this.state.in_warranty == 0
                                    &&
                                    <div class="card-header-right-expire">已过期</div>
                                }
                            </div>

                        </div>

                        <div class="card-product">保修产品：{this.state.warranty_order_id}</div>

                        <div class="card-dates">
                            <div class="card-date-item">
                                <span>{this.state.warranty_start_date}</span>
                                <span class="label">开始时间 <img class="header-shield" src={this.state.questionUrl}></img></span>
                            </div>
                            <Divider type="vertical" style={{ height: '40px', backgroundColor: '#ddd' }}></Divider>
                            <div class="card-date-item">
                                {/* <span>{this.state.warranty_expire_date} ▶</span> */}
                                {/* <svg t="1736423268425" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5211" width="200" height="200"><path d="M931.84 498.346667L112.64 20.48C105.813333 17.066667 102.4 17.066667 95.573333 20.48s-10.24 6.826667-10.24 13.653333v955.733334c0 6.826667 3.413333 10.24 10.24 13.653333 3.413333 0 6.826667 3.413333 6.826667 3.413333 3.413333 0 6.826667 0 10.24-3.413333l819.2-477.866667c6.826667-3.413333 6.826667-10.24 6.826667-13.653333s-3.413333-10.24-6.826667-13.653333z" fill="" p-id="5212"></path></svg> */}
                                <span>{this.state.warranty_expire_date} <svg t="1736423268425" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5211" width="1rem" height="1rem"><path d="M931.84 498.346667L112.64 20.48C105.813333 17.066667 102.4 17.066667 95.573333 20.48s-10.24 6.826667-10.24 13.653333v955.733334c0 6.826667 3.413333 10.24 10.24 13.653333 3.413333 0 6.826667 3.413333 6.826667 3.413333 3.413333 0 6.826667 0 10.24-3.413333l819.2-477.866667c6.826667-3.413333 6.826667-10.24 6.826667-13.653333s-3.413333-10.24-6.826667-13.653333z" fill="" p-id="5212"></path></svg></span>
                                <span class="label">到期时间</span>
                            </div>
                        </div>

                        {/* <div class="card-actions">
                    <a href="#"><Wrench style={{height:'15px'}} />申请维修</a>
                    <Divider type="vertical" style={{ height: '15px', backgroundColor: '#ddd' }}></Divider>
                    <a href="#"><Clock style={{height:'15px'}} />购买延保</a>
                </div> */}
                        <div class="card-actions">
                            <div class="card-actions-item" onClick={() => this.warrantyClick(this.state.warranty_order_id)}><div class='wrench-icon'></div>申请维修</div>
                            <div class="card-actions-item" onClick={() => this.purchaseClick()}><div class='extend-icon'></div>购买延保</div>
                        </div>
                    </div>
                </div>
                <Warrantypop
                    visible={this.state.modalVisible}
                    warranty_order_id={this.state.warranty_order_id}
                    onClose={this.closeModal}
                />
                <Purchasepop
                    visible={this.state.purchaseVisible}
                    onClose={this.closePurchaseModal}
                />
            </>
        )
    }
}

export default WarrantyDetail;
