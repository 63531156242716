import React, { useEffect, useState } from 'react';
import { Drawer, Spin, Row, Col, Button, Divider } from 'antd';  // 使用 Ant Design 组件
import { warranty_service } from '../service/warranty.service';
import './warrantypop.css'

const Purchasepop = ({ visible, warranty_order_id, onClose }) => {
    const [loading, setLoading] = useState(true);
    const [warrantyData, setWarrantyData] = useState(null);
    const phoneNumber = '400-994-6898';
    const containerStyle = {
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Arial, sans-serif',
    };

    const textStyle = {
        fontSize: '1.5rem',
        color: '#333',
        fontWeight: 'bold'
    };

    const linkStyle = {
        fontSize: '1.7rem',
        color: '#007BFF',
        textDecoration: 'none',
    };

    const servicePros = [
        {
            icon: '全面保障',
            description: '原厂配件及专业售后为您提供整机全面保障',
        },
        {
            icon: '费用无忧',
            description: '遇到任何故障，免费维修，整机零配件免费焕新',
        },
        {
            icon: '一站式服务',
            description: '电话报修，快速响应省心省时，售后无忧',
        },
    ];

    const serviceData = [
        {
            title: '① GM280新风机服务',
            details: '（2年延保+2片滤网 三种任选）',
            price: '599',
        },
        {
            title: '② GM420新风机服务',
            details: '（2年延保+2片滤网 三种任选）',
            price: '699',
        },
    ];
    // useEffect(() => {
    //     // console.log(warranty_order_id)
    //         // 获取保修信息
    //         setLoading(true);
    //         warranty_service.getWarrantyOrder(warranty_order_id).then(response => {
    //             if (response.responseCode === 'RESPONSE_OK') {
    //                 setWarrantyData(response.data);
    //             }else{
    //                 setWarrantyData(null)
    //             }
    //             setLoading(false);
    //         });

    // }, [warranty_order_id]);

    return (
        <>
            <Drawer
                className="warranty-drawer"
                style={{ overflow: 'auto', display: 'flex' }}
                title={
                    <div className="drawer-title">
                        <span className="title-text">购买延保</span>
                    </div>
                }
                visible={visible}
                onClose={onClose}
                height='auto'
                width="100%"
                footer={null}
                placement="bottom"
            >
                {/* 服务描述部分 */}
                <Row justify="center" gutter={16} className="service-row">
                    {servicePros.map((item, index) => (
                        <Col key={index} span={8} className="service-col">
                            <div className='icon-container'>
                                <div className={`circle-icon-${index}`}></div>
                            </div>
                            <div className="warranty-title">{item.icon}</div>
                            <div className="service-description">{item.description}</div>
                        </Col>
                    ))}
                </Row>

                {/* 延保服务选项部分 */}
                <Row gutter={[16, 16]} justify="center" className="warranty-row">
                    {serviceData.map((item, index) => (
                        <Col span={12} key={index} className="warranty-col">
                            <div className="warranty-card">
                                <div className="warranty-title">{item.title}</div>
                                <div className="warranty-details">{item.details}</div>
                                <div className="price-container">
                                    <span className="price-label">服务价格：</span>
                                    <span className="price-value">{item.price}</span>
                                    <span className="price-unit">元</span>
                                </div>
                                {/* <Button type="primary" className="warranty-button">
                                    立即购买
                                </Button> */}
                            </div>
                        </Col>
                    ))}
                </Row>

                {/* 客服信息部分 */}
                <div style={containerStyle}>
                    <span style={textStyle}>联系客服：</span>
                    <div href={`tel:${phoneNumber}`}>
                        {phoneNumber}
                    </div>
                </div>

            </Drawer>
        </>
    )
};

export default Purchasepop;